export class Tools {
    static isMobile() {
        let userAgent = navigator.userAgent || navigator.vendor || window['opera']
        if ((/windows phone/i.test(userAgent)) && window.innerWidth < 1150) {
            return true
        }

        if ((/android/i.test(userAgent)) && window.innerWidth < 1150) {
            return true
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if ((/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) && window.innerWidth < 1150) {
            return true
        }

        if (window.innerWidth < 1150) {
            return true
        }

        return false
    }

    static isTablet() {
        let userAgent = navigator.userAgent || navigator.vendor || window['opera']
        if ((/windows phone/i.test(userAgent)) && window.innerWidth < 790) {
            return true
        }

        if ((/android/i.test(userAgent)) && window.innerWidth < 790) {
            return true
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if ((/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) && window.innerWidth < 790) {
            return true
        }

        if (window.innerWidth < 790) {
            return true
        }

        return false
    }

    static isPhone() {
        let userAgent = navigator.userAgent || navigator.vendor || window['opera']
        if ((/windows phone/i.test(userAgent)) && window.innerWidth < 460) {
            return true
        }

        if ((/android/i.test(userAgent)) && window.innerWidth < 460) {
            return true
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if ((/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) && window.innerWidth < 460) {
            return true
        }

        if (window.innerWidth < 460) {
            return true
        }

        return false
    }
}